<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    };
  },
  created() {
  },
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

<style scoped>
>>> {
  --vs-controls-size: 0.7;
  --vs-actions-padding: 4px 2px 0 3px;
  --vs-border-color: #d8dbe0;
  --vs-selected-color: #768192;
  --vs-dropdown-option-color: #768192;
  --vs-selected-max-height: 10px;
}
</style>
