export function objectConverter(data, module){
    var str = null;
    if(module == "Global-StatusDropDownList"){
        str = { 
            value: data.ddl,
            label: data.ddl
        }
    }
    if(module == "Global-TypeLookupDropDownList"){
        str = { 
            value: data.type_id,
            label: data.type_name
        }
    }
    if(module == "Global-StatusLookupDropDownList"){
        str = { 
            value: data.status_id,
            label: data.status_name
        }
    }
    if(module == "TransactionPO-ItemProject"){
        str = { 
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            price: data.price,
            price_discount: data.price_discount,
            net: data.net,
            qty: data.qty,
            current_qty: data.current_qty,
            real_qty: data.real_qty,
            total_price: data.total_price,
            discount1: data.discount1,
            discount2: data.discount2,
            discount3: data.discount3,
            discount4: data.discount4,
            discount5: data.discount5,
            discount_cash: data.discount_cash,
            discount_amount_1: data.discount_amount_1,
            discount_amount_2: data.discount_amount_2,
            discount_amount_3: data.discount_amount_3,
            discount_amount_4: data.discount_amount_4,
            discount_amount_5: data.discount_amount_5,
            discount_amount_cash: data.discount_amount_cash,
            stock : data.stock,
            conv_amount : data.conv_amount,
            uom : data.uom,
            type_detail_id : data.type_detail_id
        }
    }
    if(module == "TransactionDO-ItemDODetail"){
        str = { 
            item_id: data.item_id.toString(),
            product_code : data.product_code,
            item_name: data.item_name.toString(),
            barcode: data.barcode,
            type_detail_id: data.type_detail_id,
            uom: data.uom,
            ordered_quantity: data.ordered_quantity,
            delivered_quantity: data.delivered_quantity,
            remaining_quantity: data.remaining_quantity,
            quantity: data.quantity,
            notes: data.notes,
            old_remaining_quantity: data.old_remaining_quantity,
        }
    }
    if(module == "TransactionDO-DetailDOArrayInput"){
        str = { 
            item_id: data.item_id.toString(),
            uom: data.uom,
            quantity: data.quantity,
            notes: data.notes,
        }
    }
    if(module == "MutasiStock-ItemData"){        
        str = { 
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            item_type: data.item_type,
            stock: data.stock,
            qty_change: data.qty,
            qty: data.qty_change,
            uom: data.uom,
            conv_amount: data.conv_amount,
        }
    }
    if(module == "Formula-TypeDetailDataAdd"){     
        str = {
            formula_detail_id: null,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            weight: 0
        }
    }
    if(module == "Formula-TypeDetailGridData"){     
        str = {
            formula_detail_id: data.formula_detail_id,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            weight: data.weight
        }
    }
    if(module == "RequestMaterial-ProductionFormulaData"){     
        str = {
            item_id: data.item_id,
            type_detail_id: data.type_detail_id,
            quantity: data.quantity,
            item_name: data.item_name,
            product_code: data.product_code
        }
    }
    if(module == "Formula-RecipeDetailData"){       
        str = {
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            qty: data.quantity,
        }
    }
    if(module == "ItemTransfer-DataDetail"){        
        str = {
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            base_uom: data.base_uom,
            stock: data.stock,
            qty_change: data.qty_change,
        }
    }
    if(module == "ItemTransfer-DetailGrid"){        
        str = {
            item_id: data.item_id,
            product_code: data.item_data.product_code,
            item_name: data.item_data.item_name,
            base_uom: data.base_uom,
            qty_request: data.qty_request,
            qty_sent: data.qty_sent,
            qty_recieved: data.qty_recieved,
        }
    }
    if(module == "Finance-DetailData"){
        str = {
            chart_of_account_id: data.chart_of_account_id,
            chart_of_account_name: data.chart_of_account_name,
            currency: data.currency,
            amount: data.amount,
            exchange_rate: data.exchange_rate,
            foreign_currency: data.foreign_currency,
            division: data.division,
            notes: data.notes,
            budgeting: data.budgeting,
            sub_project: data.sub_project,
        }
    }
    if(module == "Finance-DetailDataSave"){
        str = {
            chart_of_account_id: data.chart_of_account_id,
            chart_of_account_name: data.chart_of_account_name,
            currency: data.currency,
            // amount: data.amount,
            amount: data.foreign_currency,
            exchange_rate: data.exchange_rate,
            // foreign_currency: data.foreign_currency,
            foreign_currency: data.amount,
            division: data.division,
            notes: data.notes,
            budgeting: data.budgeting,
            sub_project: data.sub_project,
        }
    }
    if(module == "Accounting-SettingBalance"){
        str = {
            head: data.head,
            coa_group: data.coa_group,
            coa_id: data.coa_id,
        }
    }
    if(module == "Ledger-Data"){
        str = {
            id: data.id,
            transaction_number: data.transaction_number,
            transaction_date: data.transaction_date,
            contact_id: data.contact_id,
            contact_name: data.contact_name,
            desc: data.desc,
            credit: data.credit,
            debit: data.debit,
            balance: data.balance,
            notes: data.notes,
        }
    }
    if(module == "General-Ledger-Data"){
        str = {
            id: data.id,
            transaction_number: data.transaction_number,
            transaction_date: data.transaction_date,
            contact_id: data.contact_id,
            contact_name: data.contact_name,
            chart_of_account_id: data.chart_of_account_id,
            chart_of_account_name: data.chart_of_account_name,
            desc: data.desc,
            credit: data.credit,
            debit: data.debit,
            balance: data.balance,
            notes: data.notes,
            division: data.division,
            budgeting: data.budgeting,
        }
    }
    if(module == "Finance-JournalDetail"){
        str = {
            chart_of_account_id: data.chart_of_account_id,
            chart_of_account_name: data.chart_of_account_name,
            currency: data.currency,
            debit: data.debit,
            credit: data.credit,
            exchange_rate: data.exchange_rate,
            foreign_currency_debit: data.foreign_currency_debit,
            foreign_currency_credit: data.foreign_currency_credit,
            division: data.division,
            notes: data.notes,
            budgeting: data.budgeting,
            sub_project: data.sub_project,
        }
    }
    if(module == "Finance-JournalDetailSave"){
        str = {
            chart_of_account_id: data.chart_of_account_id,
            chart_of_account_name: data.chart_of_account_name,
            currency: data.currency,
            // debit: data.debit,
            // credit: data.credit,
            debit: data.foreign_currency_debit,
            credit: data.foreign_currency_credit,
            exchange_rate: data.exchange_rate,
            // foreign_currency_debit: data.foreign_currency_debit,
            // foreign_currency_credit: data.foreign_currency_credit,
            foreign_currency_debit: data.debit,
            foreign_currency_credit: data.credit,
            division: data.division,
            notes: data.notes,
            budgeting: data.budgeting,
            sub_project: data.sub_project,
        }
    }
    if(module == "CRM-DetailData"){       
        str = {
            reimburse_name: data.reimburse_name,
            reimburse_cost: data.reimburse_cost,
            notes: data.notes,
            status: data.status
        }
    }
    if(module == "ItemTransfer-DetailDataConvert"){       
        str = {
            item_id: data.item_id,
            base_uom: data.base_uom,
            qty_request: data.qty_request,
            qty_sent: data.qty_sent,
            qty_recieved: data.qty_recieved
        }
    }
    if(module == "Request-ItemGridDataPurchasingPO"){       
        str = {
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            uom: data.uom,
            qty: data.qty,
            quantity: data.quantity
        }
    }
    if(module == "Request-ItemGridDataRequest"){       
        str = {
            item_id: data.item_id,
            uom: data.uom,
            quantity: data.quantity
        }
    }
    if(module == "PurchasingDO-ItemDODetail"){
        str = { 
            item_id: data.item_id.toString(),
            product_code : data.product_code,
            item_name: data.item_name.toString(),
            uom: data.uom,
            order_quantity: data.order_quantity,
            delivered_quantity: data.delivered_quantity,
            remaining_quantity: data.remaining_quantity,
            quantity: data.quantity,
            old_remaining_quantity: data.old_remaining_quantity,
        }
    }
    if(module == "PurchasingDO-DetailDOArrayInput"){
        str = { 
            item_id: data.item_id,
            uom: data.uom,
            quantity: data.quantity,
        }
    }
    if(module == "PurchasingInvoice-InvoiceItemDetailSave"){
        var str = { 
            item_id: data.item_id,
            uom: data.uom,
            discount: data.discount,
            discount_amount: data.discount_amount,
            quantity: data.quantity,
            price_per_unit: data.price_per_unit,
            price: data.price,
            price_discount_per_unit: data.price_discount_per_unit,
            price_discount: data.price_discount,
            vat_per_unit: data.vat_per_unit,
            vat: data.vat
        }
    }
    if(module == "PurchasingPaymentInvoice-DetailData"){
        str = {
            invoice_id: data.invoice_id,
            total_payment: data.left,
            notes: data.notes,
        }
    }
    if(module == "PurchasingPaymentInvoice-OldPayableInvoice"){
        var str = { 
            invoice_id: data.invoice_id,
            invoice_number: data.invoice_number,
            tax_invoice: data.tax_invoice,
            invoice_date: data.invoice_date,
            notes: data.notes,
            total: data.total,
            paid: data.paid,
            left: 0,
            sisa: data.left,
        }
    }
    if(module == "Affiliation-ContactPerson"){
        str = {
            contact_name: data.contact_name,
            contact_phone: data.contact_phone,
            contact_position: data.contact_position,
            account_name: data.account_name,
            account_number: data.account_number,
            npwp: data.npwp
        }
    }
    //Page Project
    if(module == "Project-ProjectTypeDetail"){
        str = {
            type_detail_id: data.type_detail_id, 
            type_detail_name: data.type_detail_name,
            discount1: data.discount1,
            discount2: data.discount2,
            discount3: data.discount3,
            discount4: data.discount4,
            discount5: data.discount5,
            is_shown: data.is_shown
        }
    }
    if(module == "Project-ProjectTypeDetailForceStatus"){
        str = {
            type_detail_id: data.type_detail_id, 
            type_detail_name: data.type_detail_name,
            discount1: data.discount1,
            discount2: data.discount2,
            discount3: data.discount3,
            discount4: data.discount4,
            discount5: data.discount5,
            is_shown: data.is_shown,
            discount_cash: data.discount_cash
        }
    }
    if(module == "Project-AfiliationAdd"){
        str = {
            contact_id: data.contact_id, 
            contact_code: data.contact_code, 
            contact_name: data.contact_name,
            commision: 0
        }
    }
    if(module == "Project-ProjectAfiliation"){
        str = {
            affiliate_id: data.contact_id, 
            affiliate_code: data.contact_code, 
            affiliate_name: data.contact_name,
            commision: data.commision
        }
    }
    if(module == "Project-ProjectAfiliationGrid"){
        str = {
            affiliate_id: data.affiliate_id, 
            affiliate_code: data.affiliate_code, 
            affiliate_name: data.affiliate_name,
            commision: data.commision
        }
    }
    if(module == "Project-ProjectContact"){
        str = {
            contact_id: data.contact_id, 
            contact_code: data.contact_code, 
            contact_name: data.contact_name
        }
    }
    if(module == "Project-ProjectDistributor"){
        str = {
            distributor_id: data.contact_id, 
            distributor_code: data.contact_code, 
            distributor_name: data.contact_name
        }
    }
    if(module == "Project-ProjectDistributorGrid"){
        str = {
            distributor_id: data.distributor_id, 
            distributor_code: data.distributor_code, 
            distributor_name: data.distributor_name
        }
    }
    if(module == "Project-ProjectConsultant"){
        str = {
            consultant_id: data.contact_id, 
            consultant_code: data.contact_code,
            consultant_name: data.contact_name
        }
    }
    if(module == "Project-ProjectConsultantGrid"){
        str = {
            consultant_id: data.consultant_id, 
            consultant_code: data.consultant_code,
            consultant_name: data.consultant_name
        }
    }
    
    if(module == "Project-ProjectSalesAdd"){
        str = {
            contact_id: data.contact_id, 
            contact_code: data.contact_code,
            contact_name: data.contact_name, 
            position: data.metadata.profile.position
        }
    }
    if(module == "Project-ProjectSales"){
        str = {
            sales_id: data.contact_id,
            sales_code: data.contact_code, 
            sales_name: data.contact_name, 
            position: data.position
        }
    }
    if(module == "Project-ProjectSalesGrid"){
        str = {
            sales_id: data.sales_id,
            sales_code: data.sales_code, 
            sales_name: data.sales_name, 
            position: data.position
        }
    }
    if(module == "Project-ProjectOther"){
        str = {
            contact_id: data.contact_id,
            contact_code: data.contact_code, 
            contact_name: data.contact_name, 
            position: data.position
        }
    }
    if(module == "Project-ProjectForecastSave"){
        str = {
            purchase_order_sequence: data.po,
            item_id: data.item_id,
            uom: data.uom_conv,
            quantity: data.quantity,
            price_project: data.price_project,
            price_retail: 0,
            base_price_project: data.base_price_project,
            base_price_retail: 0,
            price_project_discount: data.price_project_discount,
            price_retail_discount: 0,
            base_price_project_discount: data.base_price_project_discount,
            base_price_retail_discount: 0,
            price_project_discount_cash: data.price_project_discount_cash,
            price_retail_discount_cash: 0,
            base_price_project_discount_cash: data.base_price_project_discount_cash,
            base_price_retail_discount_cash: 0
        }
    }
    if(module == "Project-ForecastForceStatus"){
        str = {
            purchase_order_sequence: data.purchase_order_sequence,
            item_id: data.item_id,
            uom: data.uom,
            quantity: data.quantity,
            price_project: data.price_project,
            price_retail: 0,
            base_price_project: data.base_price_project,
            base_price_retail: 0,
            price_project_discount: data.price_project_discount,
            price_retail_discount: 0,
            base_price_project_discount: data.base_price_project_discount,
            base_price_retail_discount: 0,
            price_project_discount_cash: data.price_project_discount_cash,
            price_retail_discount_cash: 0,
            base_price_project_discount_cash: data.base_price_project_discount_cash,
            base_price_retail_discount_cash: 0
        }
    }
    if(module == "Project-ForecastReverse"){
        str = {
            po: data.purchase_order_sequence,
            item_id: data.item_id,
            product_code: data.product_code, 
            item_name: data.item_name, 
            type_detail_id: data.type_detail_id, 
            base_uom: data.base_uom, 
            uom_conv: data.uom, 
            conv_amount: data.conv_amount, 
            quantity: data.quantity,
            //price
            base_price_project: data.base_price_project, 
            price_project: data.price_project, 
            total_price_project: data.price_project * data.quantity, 
            //price discount
            base_price_project_discount: data.base_price_project_discount, 
            price_project_discount: data.price_project_discount, 
            total_price_project_discount: data.price_project_discount * data.quantity, 
            //price discount cash
            base_price_project_discount_cash: data.base_price_project_discount_cash, 
            price_project_discount_cash: data.price_project_discount_cash, 
            total_price_project_discount_cash: data.price_project_discount_cash * data.quantity, 
        }
    }
    if(module == "Project-ForecastDetail"){
        str = {
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            active_flag: data.active_flag,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            group_type_id: data.group_type_id,
            item_group_name: data.item_group_name,
            base_uom: data.base_uom,
            uom_conv: data.uom_conv,
            conv_amount: data.conv_amount,
            storage_id: data.storage_id,
            price_category_id: data.price_category_id,
            price_retail: data.price_retail,
            price_project: data.price_project,
            base_price_retail: parseFloat(data.price_retail / data.conv_amount),
            base_price_project: parseFloat(data.price_project / data.conv_amount),
            stock: data.stock
        }
    }
    if(module == "Project-ForecastInitiate"){
        str = {
            po: data.po, 
            item_id: data.item_id,
            product_code: data.product_code, 
            item_name: data.item_name,
            type_detail_id: data.type_detail_id,
            base_uom: data.base_uom,
            uom_conv: data.uom_conv,
            conv_amount: data.conv_amount,
            quantity: data.quantity,
            //price
            base_price_project: data.base_price_project,
            price_project: data.price_project,
            total_price_project: data.total_price_project,
            //price discount
            base_price_project_discount: data.base_price_project_discount,
            price_project_discount: data.price_project_discount,
            total_price_project_discount: data.total_price_project_discount,
            //price discount cash
            base_price_project_discount_cash: data.base_price_project_discount_cash,
            price_project_discount_cash: data.price_project_discount_cash,
            total_price_project_discount_cash: data.total_price_project_discount_cash,
        }
    }
    if(module == "Retail-ForecastSave"){
        str = {
            purchase_order_sequence: data.po,
            item_id: data.item_id,
            uom: data.uom_conv,
            quantity: data.quantity,
            price_project: 0,
            price_retail: data.price_retail,
            base_price_project: 0,
            base_price_retail: data.base_price_retail,
            price_project_discount: 0,
            price_retail_discount: data.price_retail_discount,
            base_price_project_discount: 0,
            base_price_retail_discount: data.base_price_retail_discount,
            price_project_discount_cash: 0,
            price_retail_discount_cash: data.price_retail_discount_cash,
            base_price_project_discount_cash: 0,
            base_price_retail_discount_cash: data.base_price_retail_discount_cash
        }
    }
    if(module == "Retail-ForecastForceStatus"){
        str = {
            purchase_order_sequence: data.purchase_order_sequence,
            item_id: data.item_id,
            uom: data.uom,
            quantity: data.quantity,
            price_project: 0,
            price_retail: data.price_retail,
            base_price_project: 0,
            base_price_retail: data.base_price_retail,
            price_project_discount: 0,
            price_retail_discount: data.price_retail_discount,
            base_price_project_discount: 0,
            base_price_retail_discount: data.base_price_retail_discount,
            price_project_discount_cash: 0,
            price_retail_discount_cash: data.price_retail_discount_cash,
            base_price_project_discount_cash: 0,
            base_price_retail_discount_cash: data.base_price_retail_discount_cash
        }
    }
    if(module == "Retail-ForecastReverse"){
        str = {
            po: data.purchase_order_sequence,
            item_id: data.item_id,
            product_code: data.product_code, 
            item_name: data.item_name, 
            type_detail_id: data.type_detail_id, 
            base_uom: data.base_uom, 
            uom_conv: data.uom, 
            conv_amount: data.conv_amount, 
            quantity: data.quantity,
            //price
            base_price_retail: data.base_price_retail, 
            price_retail: data.price_retail, 
            total_price_retail: data.price_retail * data.quantity, 
            //price discount
            base_price_retail_discount: data.base_price_retail_discount, 
            price_retail_discount: data.price_retail_discount, 
            total_price_retail_discount: data.price_retail_discount * data.quantity, 
            //price discount cash
            base_price_retail_discount_cash: data.base_price_retail_discount_cash, 
            price_retail_discount_cash: data.price_retail_discount_cash, 
            total_price_retail_discount_cash: data.price_retail_discount_cash * data.quantity, 
        }
    }
    if(module == "Retail-ForecastInitiate"){
        str = {
            po: data.po, 
            item_id: data.item_id,
            product_code: data.product_code, 
            item_name: data.item_name,
            type_detail_id: data.type_detail_id,
            base_uom: data.base_uom,
            uom_conv: data.uom_conv,
            conv_amount: data.conv_amount,
            quantity: data.quantity,
            //price
            base_price_retail: data.base_price_retail,
            price_retail: data.price_retail,
            total_price_retail: data.total_price_retail,
            //price discount
            base_price_retail_discount: data.base_price_retail_discount,
            price_retail_discount: data.price_retail_discount,
            total_price_retail_discount: data.total_price_retail_discount,
            //price discount cash
            base_price_retail_discount_cash: data.base_price_retail_discount_cash,
            price_retail_discount_cash: data.price_retail_discount_cash,
            total_price_retail_discount_cash: data.total_price_retail_discount_cash,
        }
    }
    if(module == "Transaction-PO-Project"){
        str = { 
            value: data.project_id,
            label: data.project_name
        }
    }
    if(module == "Transaction-Invoice-InvoiceItemDetailSave"){
        str = { 
            item_id: data.item_id,
            item_name: data.item_name,
            product_code : data.product_code,
            uom: data.uom,
            base_uom: data.base_uom,
            price_per_unit: data.price_per_unit,
            price: data.price,
            vat_per_unit: data.vat_per_unit,
            vat: data.vat,
            price_discount_per_unit: data.price_discount_per_unit,
            price_discount: data.price_discount,
            fulfilled_quantity: data.quantity,
            conv_amount: data.conv_amount,
            discount1: data.discount1,
            discount2: data.discount2,
            discount3: data.discount3,
            discount4: data.discount4,
            discount5: data.discount5,
            discount_cash: data.discount_cash,
            discount_plus: parseFloat(data.discount_plus),
            discount1_amount: data.discount1_amount,
            discount2_amount: data.discount2_amount,
            discount3_amount: data.discount3_amount,
            discount4_amount: data.discount4_amount,
            discount5_amount: data.discount5_amount,
            discount_cash_amount: data.discount_cash_amount,
            discount_plus_amount: data.discount_plus_amount,
            tax_amount: data.tax_amount,
            deposit_amount: data.deposit_amount,
            //RoundDown
            tax_amount_rd: data.tax_amount_rd,
            vat_per_unit_rd: data.vat_per_unit_rd,
            vat_rd: data.vat_rd,
        }
    }
    if(module == "Transaction-Invoice-InvoiceItemDetailEdit"){
        str = { 
            item_id: data.item_id,
            product_code : data.product_code,
            item_name: data.item_name,
            uom: data.uom,
            base_uom: data.base_uom,
            price_per_unit: data.price_per_unit,
            price: data.price,
            vat_per_unit: data.vat_per_unit,
            vat: data.vat,
            price_discount_per_unit: data.price_discount_per_unit,
            price_discount: data.price_discount,
            quantity: data.fulfilled_quantity,
            conv_amount: data.conv_amount,
            discount1: data.discount1,
            discount2: data.discount2,
            discount3: data.discount3,
            discount4: data.discount4,
            discount5: data.discount5,
            discount_cash: data.discount_cash,
            discount_plus: data.discount_plus,
            discount1_amount: data.discount1_amount,
            discount2_amount: data.discount2_amount,
            discount3_amount: data.discount3_amount,
            discount4_amount: data.discount4_amount,
            discount5_amount: data.discount5_amount,
            discount_cash_amount: data.discount_cash_amount,
            discount_plus_amount: data.discount_plus_amount,
            tax_amount: data.tax_amount,
            deposit_amount: data.deposit_amount,
            //RoundDown
            tax_amount_rd: data.tax_amount_rd,
            vat_per_unit_rd: data.vat_per_unit_rd,
            vat_rd: data.vat_rd,
        }
    }
    if(module == "Transaction-Invoice-InvoiceItemDetail"){
        str = { 
            item_id: data.item_id,
            item_name: data.item_name,
            product_code : data.product_code,
            uom: data.uom,
            base_uom: data.base_uom,
            price_per_unit: data.price_per_unit,
            price: data.price,
            vat_per_unit: data.vat_per_unit,
            vat: data.vat,
            price_discount_per_unit: data.price_discount_per_unit,
            price_discount: data.price_discount,
            fulfilled_quantity: data.fulfilled_quantity,
            conv_amount: data.conv_amount,
            discount1: data.discount1,
            discount2: data.discount2,
            discount3: data.discount3,
            discount4: data.discount4,
            discount5: data.discount5,
            discount_cash: data.discount_cash,
            discount_plus: parseFloat(data.discount_plus),
            discount1_amount: data.discount1_amount,
            discount2_amount: data.discount2_amount,
            discount3_amount: data.discount3_amount,
            discount4_amount: data.discount4_amount,
            discount5_amount: data.discount5_amount,
            discount_cash_amount: data.discount_cash_amount,
            discount_plus_amount: data.discount_plus_amount,
            tax_amount: data.tax_amount,
            deposit_amount: data.deposit_amount,
            //RoundDown
            tax_amount_rd: data.tax_amount_rd,
            vat_per_unit_rd: data.vat_per_unit_rd,
            vat_rd: data.vat_rd,
        }
    }
    if(module == "Transaction-PaymentInvoice-InvoicePaymentDetail"){
        str = {
            invoice_id: data.invoice_id,
            total_payment: data.left,
            notes: data.notes,
            paid_amount: data.left
        }
    }
    if(module == "TechnicalSupport-TechnicalSupportRequest-Project"){
        str = { 
            value: data.project_id,
            label: data.project_name,
            sales_id: data.sales_id,
            sales_name: data.sales_name,
        }
    }
    if(module == "TechnicalSupport-TechnicalSupportRequest-Visitor"){
        str = { 
            value: data.contact_id,
            label: data.contact_name,
        }
    }
    if(module == "TechnicalSupport-TechnicalSupportRequest-VisitorGrid"){
        str = { 
            visitor_id: data.visitor_id,
            visitor_name: data.visitor_name,
        }
    }
    if(module == "TechnicalSupport-TechnicalSupportVisit"){
        str = { 
            value: data.visitor_id,
            label: data.visitor_name
        }
    }
    if(module == "Formula-MachineDropdown"){
        str = { 
            value: data.machine_id,
            label: data.machine_name,
        }
    }
    if(module == "Formula-MachineGrid"){
        str = { 
            machine_id: data.machine_id,
            machine_name: data.machine_name,
            duration_min: data.duration_min
        }
    }
    if(module == "Production-ProductionMaterial"){
        str = { 
            item_id: data.item_id.toString(),
            type_detail_id: data.type_detail_id.toString(),
            quantity: data.qty,
            actual_quantity: data.actual_quantity
        }
    }
    if(module == "ProductionInvoice-DetaiFormGrid"){
        str = { 
            item_id: data.item_id,        
            product_code: data.product_code,
            item_name: data.item_name,
            uom: data.uom,
            quantity: data.quantity,
            discount: data.discount,
            price_per_unit: data.price_per_unit,
            price: data.price,
            vat_per_unit: data.vat_per_unit,
            vat: data.vat,
        }
    }
    if(module == "ProductionInvoice-ItemDetail"){
        str = { 
            item_id: data.item_id,
            uom: data.uom,
            quantity: data.quantity,
            discount: data.discount,
            price_per_unit: data.price_per_unit,
            price: data.price,
            vat_per_unit: data.vat_per_unit,
            vat: data.vat,
        }
    }
    if(module == "RoleDashboard-Data"){     
        str = {
            dashboard_id: data.dashboard_id,
            dashboard_name: data.dashboard_name
        }
    }
    if(module == "RoleRegion-Data"){     
        str = {
            region_id: data.region_id,
            region_code: data.region_code,
            region_name: data.region_name
        }
    }
    if(module == "RoleRegion-Create"){     
        str = {
            region_id: data.id,
            region_code: data.region_code,
            region_name: data.region_name
        }
    }
    if(module == "ProductionInvoicePayment-Detail"){
        str = {
            invoice_id: data.invoice_id,
            total_payment: data.left,
            notes: data.notes,
        }
    }
    if(module == "ProductionDeliveryOrder-ItemDetail"){
        str = { 
            product_code: data.product_code,
            item_name: data.item_name,
            uom: data.uom,
            invoice_quantity: data.invoice_quantity,
            delivered_quantity: data.delivered_quantity,
            remaining_quantity: data.remaining_quantity,
            quantity: data.quantity
        }
    }
    if(module == "Item-DetailData"){
        str = {
            uom_conv: data.uom_conv,
            conv_amount: data.conv_amount,
            is_secondary: data.is_secondary,
        }
    }
    if(module == "Distributor-ShippingAddressData"){
        str = {
            address: data,
        }
    }
    if(module == "Distributor-OverdueData"){
        str = {
            group_type_id: data.group_type_id,
            group_type_name: data.group_type_name,
            top_project: data.top_project
        }
    }
    if(module == "BorrowingTools-ItemDetailGet"){
        str = { 
            project_borrowed_tool_detail_id: data.project_borrowed_tool_detail_id,
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            uom: data.uom,
            quantity: data.quantity,
            notes: data.notes,
            status: data.status,
            created_by: data.created_by,
            created_at: kendo.toString(kendo.parseDate(new Date(data.created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd'),
            approved_by: data.approved_by,
        }
    }
    if(module == "BorrowingTools-ItemDetailSave"){
        str = { 
            project_borrowed_tool_detail_id: data.project_borrowed_tool_detail_id,
            item_id: data.item_id,
            uom: data.uom,
            quantity: data.quantity,
            notes: data.notes,
            status: data.status,
        }
    }
    
    if(module == "CommissionEstimation-ContactDetailGet"){
        str = { 
            commission_estimation_id: data.commission_estimation_id,
            commission_detail_estimation_id: data.commission_detail_estimation_id,
            commission_detail_estimation_number: data.commission_detail_estimation_number,
            commission_id: data.commission_id,               
            commission_contact_id: data.commission_contact_id,

            status: data.status,
            max_commission: data.max_commission,
            given_commission: data.given_commission,
            notes: data.notes,

            head_contact_name: data.head_contact_name,
            contact_person_name: data.contact_person_name,
            phone_number: data.phone_number,
            position: data.position,
            bank_account_number: data.bank_account_number,
            bank_account_name: data.bank_account_name,
            npwp: data.npwp,

            created_by: data.created_by,
            created_at: kendo.toString(kendo.parseDate(new Date(data.created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd'),
            approved_by: data.approved_by,
        }
    }
    if(module == "CommissionEstimation-ContactDetailSave"){
        str = { 
            commission_estimation_id: parseInt(data.commission_estimation_id),
            commission_detail_estimation_id: parseInt(data.commission_detail_estimation_id),
            commission_id: parseInt(data.commission_id),

            status: data.status,
            max_commission: parseFloat(data.max_commission),
            given_commission: parseFloat(data.given_commission),
            notes: data.notes,

            new_commission_contact: {
                head_contact_name: data.head_contact_name,
                contact_person_name: data.contact_person_name,
                phone_number: data.phone_number,
                position: data.position,
                bank_account_number: data.bank_account_number,
                bank_account_name: data.bank_account_name,
                npwp: data.npwp,
            },
        }
    }
    if(module == "MachineType-TypeDetailSave"){
        str = { 
            machine_type_detail_id: data.machine_type_detail_id,
            machine_type_id: data.machine_type_id,
            type_detail_id: data.type_detail_id,
        }
    }
    if(module == "MachineType-TypeDetailGet"){
        str = { 
            machine_type_detail_id: data.machine_type_detail_id,
            machine_type_id: data.machine_type_id,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
        }
    }
    if(module == "Machine-MachineItemSave"){
        str = { 
            machine_item_list_id: data.machine_item_list_id,
            machine_id: data.machine_id,
            item_id: data.item_id,
            processing_time: data.processing_time
        }
    }
    if(module == "Machine-MachineItemGet"){
        str = { 
            machine_item_list_id: data.machine_item_list_id,
            machine_id: data.machine_id,
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            base_uom: data.base_uom,
            processing_time: data.processing_time,
        }
    }
    if(module == "ProductionPlanning-MaterialGrid"){
        str = { 
            production_planing_detail_id: data.production_planing_detail_id,
            storage_id: data.storage_id,
            storage_name: data.storage_name,
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            base_uom: data.base_uom,
            weight: data.weight,
            quantity: data.quantity,
            total: data.total,
        }
    }
    if(module == "Maintenance-MaintenanceItemSave"){
        str = {
            maintenance_item_usage_id: data.maintenance_item_usage_id,
            maintenance_id: data.maintenance_id,
            item_id: data.item_id,
            quantity: data.quantity,
        }
    }
    if(module == "Maintenance-MaintenanceItemGet"){
        str = {
            maintenance_item_usage_id: data.maintenance_item_usage_id,
            maintenance_id: data.maintenance_id,
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            base_uom: data.base_uom,
            quantity: data.quantity,
        }
    }
    if(module == "ProductionSchedule-ScheduleGrid"){
        str = {
            production_id: data.production_id,
            production_planning_id: data.production_planning_id,
            production_schedule_id: data.production_schedule_id,
            production_schedule_name: data.production_schedule_name,
            production_schedule_duration: data.production_schedule_duration,
        }
    }
    if(module == "RequestMaterial-MutationItemGrid"){
        str = {
            production_mutation_id: data.production_mutation_id,
            production_mutation_item_id: data.production_mutation_item_id,
            storage_id: data.storage_id,
            item_id: data.item_id,
            item_type: data.item_type,
            product_code: data.product_code,
            item_name: data.item_name,
            base_uom: data.base_uom,
            requested_quantity: data.requested_quantity,
            total_approved_quantity: data.total_approved_quantity,
            secondary_uom: data.secondary_uom,
            secondary_conv_amount: data.secondary_conv_amount,
            secondary_requested_quantity: data.secondary_requested_quantity,
            secondary_total_approved_quantity: data.secondary_total_approved_quantity,
        }
    }
    if(module == "RequestMaterial-SaveMutationItemGrid"){
        str = {
            production_mutation_id: data.production_mutation_id,
            production_mutation_item_id: data.production_mutation_item_id,
            storage_id: data.storage_id,
            item_id: data.item_id,
            item_type: data.item_type,
            requested_quantity: data.requested_quantity,
            total_approved_quantity: data.total_approved_quantity,
        }
    }
    if(module == "QualityControl-WasteRejectedItemGrid"){
        str = {
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            base_uom: data.base_uom,
            quantity: data.quantity,
            secondary_uom: data.secondary_uom,
            secondary_conv_amount: data.secondary_conv_amount,
            secondary_quantity: data.secondary_quantity,
            mutation_type: data.mutation_type,
        }
    }
    if(module == "ProductionMaster-RequestMaterialGrid"){
        str = {
            material_id: data.material_id,
            material_code: data.material_code,
            material_name: data.material_name,
            storage_id: data.storage_id,
            type_detail_id: data.type_detail_id,
            quantity: data.quantity,
        }
    }
    if(module == "ProductionMaster-MaterialGrid"){
        str = {
            material_id: data.item_id,
            material_code: data.product_code,
            material_name: data.item_name,
            storage_id: data.storage_id,
            type_detail_id: data.type_detail_id,
            quantity: data.quantity,
        }
    }
    if(module == "ProductionMaster-WasteItemGrid"){
        str = {
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            quantity: data.quantity,
        }
    }
    return str;
}